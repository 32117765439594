<template>
  <div class="nameAndIdentitycard-wrap">
    <div class="tip">为了保证电子签约的法律效力，您需要先进行实名认证</div>
    <div class="content">
      <div class="label">姓名</div>
      <van-field
        v-model="userFullName"
        placeholder="请输入真实姓名"
        :disabled="disabled"
      />
      <div class="label">证件号</div>
      <van-field
        v-model="userIdCardNo"
        placeholder="请输入证件号"
        :disabled="disabled"
      />
      <div class="label">证件类型</div>
      <van-cell is-link @click="showPopup">{{ typeStr }}</van-cell>
      <!-- <div class="des">
        <van-checkbox
          v-model="checked"
          checked-color="#1676FF"
        ></van-checkbox>
        <p>
          我已阅读并同意
          <span>《隐私保护协议》</span>
        </p>
      </div> -->
    </div>
    <div class="footer" v-if="!isPopup">
      <template v-if="canClick">
        <div class="btn" @click="twoElements">下一步</div>
      </template>
      <template v-else>
        <div class="btn btn2">下一步</div>
      </template>
    </div>
    <van-popup v-model="show" position="bottom">
      <van-picker
        title="标题"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
        @change="onChange"
      />
    </van-popup>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import Api from '@/api'
  import myApis from '@/api/my'
  import AuthApi from '@/api/creadit'
  import { Dialog, Toast } from 'vant'

  export default {
    props: ['isPopup'],
    data() {
      return {
        userFullName: '',
        userIdCardNo: '',
        checked: true,
        disabled: false,
        show: false,
        columns: ['内地', '台湾', '港澳'],
        typeStr: '内地',
        type: 1,
      }
    },
    computed: {
      ...mapState({
        systemInfo: state => state.system,
      }),
      canClick() {
        if (this.userFullName && this.userIdCardNo && this.checked) return true
        return false
      },
    },
    created() {
      const { url } = this.$route.query
      this.userId = this.$store.state.user.userInfo.userId
      if (!this.userId) {
        this.$router.push({
          path: '/My',
        })
      }
      this.telephone = this.$store.state.user.userInfo.telephone
      this.$store.commit('setSignUrl', url)
      this.$store.commit('setCreditType', 100)
    },
    methods: {
      showPopup() {
        this.show = true
      },
      onConfirm(value, index) {
        this.typeStr = value
        this.type = index + 1
        this.show = false
      },
      onChange(picker, value, index) {
        // Toast(`当前值：${value}, 当前索引：${index}`)
      },
      onCancel() {
        this.show = false
      },
      twoElements() {
        Api.twoElements({
          userFullName: this.userFullName,
          userIdCardNo: this.userIdCardNo,
          userId: this.userId,
          systemId: this.systemInfo.systemId,
          type: this.type,
        }).then(res => {
          const { data, status, msg } = res
          if (status === 201) {
            Dialog.alert({
              title: '',
              message: msg,
              confirmButtonText: '重新输入',
            }).then(() => {
              this.userFullName = ''
              this.userIdCardNo = ''
            })
            return
          }
          if (status === 200) {
            this.$store.commit('setCreditMsg', {
              userFullName: this.userFullName,
              userIdCardNo: this.userIdCardNo,
              userId: this.userId,
              telephone: this.telephone,
            })
            this.$router.push('/auth')
            return
          }
          Toast(msg)
        })
      },
    },
  }
</script>
<style lang="less" scoped>
  .nameAndIdentitycard-wrap {
    width: 100%;
    .header {
      width: 375px;
      height: 40px;
      background: rgba(248, 249, 251, 1);
      padding: 0 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      .left {
        height: 16px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(109, 119, 144, 1);
        line-height: 16px;
      }
      .right {
        display: flex;
        align-items: center;
        img {
          width: 11px;
          height: 14px;
          margin-right: 8px;
        }
        height: 16px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(22, 187, 131, 1);
        line-height: 16px;
      }
    }
    .tip {
      font-size: 14px;
      padding: 16px;
      color: rgba(22, 118, 255, 1);
    }
    .content {
      padding: 0 16px;
      .label {
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(17, 26, 52, 1);
        line-height: 22px;
        margin-bottom: 8px;
      }
      /deep/.van-cell {
        padding: 13px 0;
        height: 48px;
        margin-bottom: 24px;
      }
      .des {
        display: flex;
        align-items: center;
        height: 24px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(109, 119, 144, 1);
        line-height: 24px;
        span {
          color: rgba(22, 118, 255, 1);
        }
        p {
          margin-left: 8px;
        }
      }
      .van-cell::after {
        position: absolute;
        box-sizing: border-box;
        content: ' ';
        pointer-events: none;
        right: 0;
        bottom: 0;
        left: 0;
        border-bottom: 0.02667rem solid #ebedf0;
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
      }
      .van-cell:last-child::after {
        display: inline-block;
      }
    }
    .footer {
      position: fixed;
      bottom: @safe-area;
      left: 0;
      width: 375px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px -6px 10px 0px rgba(230, 234, 237, 0.3);
      padding: 10px 0;
      .btn {
        width: 327px;
        height: 44px;
        background: rgba(22, 118, 255, 1);
        box-shadow: 0px 6px 20px 0px rgba(78, 128, 245, 0.16);
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        line-height: 44px;
        text-align: center;
        margin: 0 auto;
      }
      .btn2 {
        background: rgba(22, 118, 255, 0.2);
      }
    }
  }
</style>
